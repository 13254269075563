import { Slot } from 'primitives/slot'
import * as React from 'react'
import { tw } from 'utils/classnames'

type StudioButtonVariant = 'fill' | 'dark' | 'gray' | 'outline' | 'invisible' | 'danger'

type Sizes = 'small' | 'medium' | 'tiny'
type Gestures = 'scale' | 'none'

interface StudioButtonProps {
  variant?: StudioButtonVariant
  size?: Sizes
  gesture?: Gestures
  asChild?: boolean
}

function StudioButtonRoot(
  {
    size = 'medium',
    gesture = 'none',
    variant = 'fill',
    asChild,
    ...props
  }: React.ComponentProps<'button'> & StudioButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const className = tw(
    'rounded-2 text-14 outline-none',
    props.disabled && 'cursor-not-allowed',
    studioButtonClasses.sizes[size],
    studioButtonClasses.variants[variant],
    props.className,
  )

  const gestures = getGestureProps(gesture)

  const Comp = asChild ? Slot : 'button'

  return <Comp {...gestures} {...props} className={className} ref={ref} />
}

export function ButtonIcon(props: React.ComponentProps<'svg'>) {
  const Comp = Slot as unknown as 'svg'

  return <Comp {...props} className={tw('size-6', props.className)} />
}

export const StudioButton = Object.assign(React.forwardRef(StudioButtonRoot), {
  Icon: ButtonIcon,
})

const studioButtonClasses = {
  variants: {
    fill: [
      'text-service-white-white bg-violet-500',
      'hover:bg-[#6844C9]',
      'active:bg-[#552DC1] active:text-service-white-white focus:bg-violet-500 focus:text-service-white-white',
      'disabled:bg-gray-700 disabled:text-gray-400 disabled:hover:bg-gray-700 disabled:hover:text-gray-400',
    ].join(' '),
    invisible: [
      'bg-transparent',
      'hover:text-violet-500',
      'active:text-[#552DC1] focus:text-[#552DC1]',
      'disabled:bg-transparent disabled:text-gray-400-400 disabled:hover:bg-transparent disabled:hover:text-gray-400-400',
    ].join(' '),
    dark: [
      'bg-gray-900 text-gray-50',
      'hover:bg-[#6844C9] hover:text-service-white-white',
      'active:bg-[#552DC1] active:text-service-white-white focus:bg-[#552DC1] focus:text-service-white-white',
      'disabled:bg-gray-700 disabled:text-gray-400 disabled:hover:bg-gray-700 disabled:hover:text-gray-400',
    ].join(' '),
    gray: [
      'bg-gray-500-300 text-gray-50',
      'hover:text-service-white-white hover:bg-[#6844C9]',
      'active:text-service-white-white active:bg-[#552DC1] focus:text-service-white-white focus:bg-[#552DC1]',
      'disabled:bg-gray-700 disabled:text-gray-400 disabled:hover:bg-gray-700 disabled:hover:text-gray-400',
    ].join(' '),
    outline: [
      'bg-transparent text-gray-50 border border-gray-700 text-gray-50',
      'hover:border-violet-500 hover:text-violet-500 active:text-[#552DC1]',
      'active:border-[#552DC1] focus:border-[#552DC1]',
      'disabled:bg-transparent disabled:text-gray-400-400 disabled:border-gray-400-400',
    ].join(' '),
    danger: [
      'text-service-white-white bg-red-100',
      'hover:bg-[#AF1133]',
      'active:bg-[#990827] focus:bg-[#990827]',
      'disabled:bg-gray-700 disabled:text-gray-400 disabled:hover:bg-gray-700 disabled:hover:text-gray-400',
    ].join(' '),
  },
  sizes: {
    medium: 'h-10 flex items-center px-4',
    small: 'h-8 flex items-center px-3',
    tiny: 'h-6 flex items-center px-2',
  },
}

function getGestureProps(gesture?: Gestures) {
  if (gesture === 'scale') {
    return {
      whileHover: {
        scale: 1.1,
      },
      whileTap: {
        scale: 1.05,
      },
    }
  }

  return {}
}
